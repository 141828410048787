import { FAQSUI } from "features";
import styles from "./styles.module.scss";

const FAQS = () => {
  return (
    <>
      <FAQSUI />
    </>
  );
};

export { FAQS };
